.footer {
    background-color: #00355B;
    padding: 2.5em 0;
    color: white;
}

.footerup {
    padding-bottom: 40px;
}


.footersocial {
    padding-top: 20px;
    color: white;
    display: flex;
    column-gap: 40px;
}


.footerlogo {
    max-width: 120px;
}

.footer a,
.footer .btn.p-0 {
    text-decoration: none;
    color: #ffffff;
}
.footer a:hover,
.footer .btn.p-0:hover {
    opacity: 0.6;
}
.footer .btn.p-0 {
    display: block;
    width: 100%;
    text-align: left;
    margin: 3px 0;
    font-weight: 400;
}

.footerdown {
    border-top: solid 1px rgba(255,255,255,0.5);
    padding-top: 1.5em;
}
.footMap {
    line-height: 0;
    background-color: #c2c2c2;
}
.footMap iframe {
    width: 100%;
    height: 110px;
}

@media (max-width: 991px) {
    .footerup,
    .footerdown {
        max-width: 600px;
        margin: auto;
        text-align: center;
    }
    .footer .footersocial,
    .footer .d-flex {
        display: inline-flex !important;
        margin: 0.5em;
    }
    .footer .btn.btn-white.mb-3 {
        width: 100%;
    }
    .footer .btn.p-0 {
        text-align: center;
    }
    .footer .footerdown.d-flex {
        display: block !important;
        margin: auto !important;
    }
    .footer .footerdown.d-flex div:first-of-type {
        padding-bottom: 1em;
    }
}

@media (max-width: 767px) {
    .footer .footerup,
    .footer .footerdown {
        max-width: 300px;
    }
}