.logForm {
    max-width: 600px;
    margin: 0 auto;
    color: #00355B;
}
.logForm p {
    margin: 0 0 6px;
    color: #414042;
    text-align: left;
    font-size: 85%;
}
.logForm .form-control {
    border-color: #00355B;
    margin-bottom: 5px;
}
.rerrors {
    color: #cf0000;
}
.logForm p.continue {
    color: #798995;
    margin: 1em 0;
    text-align: center;
}

.conWidth .btn.btn-outline-primary {
    background-color: #ffffff;
    border-color: #00355B;
    color: #00355B;
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    line-height: 1.3;
    width: 100%;
    outline: none;
    margin: 0 0 1.2em;
}
.conWidth .btn img,
.conWidth .btn svg {
    max-width: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
}
a.forgot {
    text-decoration: none;
    color: #465685;
    font-size: 70%;
}

.logForm > .related {
    position: relative;
}
.logForm > .related > .rerrors {
    display: block;
    position: absolute;
    width: 100%;
    bottom: -20px;
    left: 0;
    font-size: 12px;
    text-align: center;
}

@media (min-width: 992px) {
    .logFormOuter {
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
    }
}