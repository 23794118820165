/* === admission CSS Only === */

.hdrTitle h3,
.formDetails h6 {
    color: #00355B;
}

.adMtabsWrap .MuiTabs-fixed {
    border-bottom: solid 1px #b5b5b5;
    margin-bottom: 1.5em !important;
}
.adMtabsWrap .MuiTabs-indicator {
    display: none !important;
}
.adMtabsWrap button.MuiButtonBase-root {
    color: #555555 !important;
    font-family: 'Work Sans', sans-serif !important;
    border-style: solid;
    border-width: 0 0 3px;
    border-color: transparent;
    font-weight: 500 !important;
    text-transform: none !important;
    margin-right: 1em;
    min-height: 0;
}
.adMtabsWrap button.MuiButtonBase-root.Mui-selected {
    border-bottom-color: #00355B;
    font-weight: 700 !important;
    color: #00355B !important;
}

.proAvatarUpload {
    width: 192px;
    height: 192px;
    position: relative;
    -webkit-border-radius: 32px;
    border-radius: 32px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #b5b5b5;
    background-image: url('../../../images/avatar-png-profile-icon.png');
}
button.uploadBtn {
    position: absolute;
    right: -12px;
    bottom: -12px;
    z-index: 4;
    width: 48px;
    height: 48px;
    background-color: #ffffff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: center;
    background-image: url('../../../images/svg/uploadPro-ico.svg');
    border: none;
    outline: none;
}
button.uploadBtn input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    opacity: 0;
}
.formDetails {
    padding: 1em 0;
}
.formDetails .form-label {
    font-weight: 400;
}
.formDetails .form-control,
.formDetails .form-select {
    border-color: transparent;
    background-color: #F2F2F7;
    color: #00355B;
    font-weight: 600;
}
.formDetails .logTxtDnger {
    display: block;
    padding-top: 3px;
    font-size: 12px;
    color: #cf0000;
}

.adMtabsWrap .footBtn {
    border-top: solid 1px #C7D2D6;
    text-align: right;
    margin-top: 1em;
}

