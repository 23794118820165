/* === Admin Only === */

.adminHdr {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    background-color: #ffffff;
    border-bottom: solid 2px #E9EBF0;
}
.adminHdr.scrolled {    
    -webkit-box-shadow: 0 0 4px rgba(0,0,0,0.6);
    box-shadow: 0 0 4px rgba(0,0,0,0.6);
}

.unFLetter {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.3;
    margin-right: 4px;
    max-width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
}

.schBox {
    position: relative;
}
.schBox .ico {
    display: inline-block;
    position: absolute;
    left: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0.5;
}
.schBox .form-control {
    padding-left: 2.8rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: rgba(0,0,0,0.02);
    -webkit-box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
    box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
}

.inrPageTPAdmin {
    padding-top: 99px;
}

.bdrR {
    border-right: solid 2px #E9EBF0;
}
.bdrT {
    border-top: solid 2px #E9EBF0;
}

.adminSideNav,
.dashB {
    overflow-x: hidden;
    overflow-y: auto;
}

.dashB {
    padding-right: 1rem;
    max-height: calc(100vh - 156px);
}
.adminSideNav ul,
.adminSideNav ul li {
    list-style: none;
    margin: 0;
    padding: 0; 
}
.adminSideNav ul li {
    margin-bottom: 6px;
}
.adminSideNav ul li a {
    display: block;
    position: relative;
    padding: 12px 90px 12px 60px;
    border-left: solid 5px transparent;
    text-decoration: none;
    font-family: 'Inter', sans-serif;
    font-size: 0.85rem;
    color: #1F2633;
    font-weight: 600;
}
.adminSideNav ul li a:hover {
    background-color: #f1f1f1;
}
.adminSideNav ul li a.active {
    background-color: rgba(37, 99, 235, 0.1);
    border-left-color: #00355B;
}
.adminSideNav ul li a::before {
    content: '';
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 1.2rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.adminSideNav ul li a.dashboard::before {
    background-image: url('../../images/svg/space_dashboard.svg');
}
.adminSideNav ul li a.onlineApp::before {
    background-image: url('../../images/svg/bx_news.svg');
}
.adminSideNav ul li a.announcement::before {
    background-image: url('../../images/svg/mdi_announcement.svg');
}
.adminSideNav ul li a.writtenForms::before {
    background-image: url('../../images/svg/drive_folder_upload.svg');
}
.adminSideNav ul li a.news::before {
    background-image: url('../../images/svg/fluent_news-20-filled.svg');
}
.adminSideNav ul li a.emails::before {
    background-image: url('../../images/svg/emails.svg');
    width: 20px;
    height: 16px;
}
.adminSideNav ul li a .badge {
    display: inline-block;
    padding: 5px 8px;
    background-color: rgba(59, 130, 246, 0.1);
    -webkit-border-radius: 50rem;
    border-radius: 50rem;
    font-size: 10px;
    font-weight: 8600;
    color: #00355B;
    position: absolute;
    right: 2rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.inrPageTPAdmin {
    color: #00355B;
}
.inrPageTPAdmin .input-group {
    border: solid 1px #E9EBF0;
    -webkit-border-radius: 6px;
    border-radius: 6px;
}
.input-group .input-group-text {
    background-color: transparent;
    opacity: 0.9;
    padding: 0;
    color: #00355B;
    padding: 0 12px;
}
.inrPageTPAdmin .input-group .input-group-text,
.inrPageTPAdmin .input-group .form-control {
    border: none;
}
.inrPageTPAdmin .input-group .input-group-text svg {
    width: 16px;
}
.inrPageTPAdmin .input-group .form-control,
.inrPageTPAdmin .input-group .form-select {
    width: 160px;
}

.dashTitleBox {  
    padding: 1.2rem 1rem 3.5rem 2.3rem;  
    background: #E9EFFD;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    position: relative;
}
.dashTitleBox::before {
    content: '';
    width: 7px;
    height: 100%;
    background-color: #00355B;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.inrPageTPAdmin button.btn-outline-primary {
    border-color: #00355B;
    color: #00355B;
}
.inrPageTPAdmin button.btn-outline-secondary {
    border-color: #E9EBF0;
}
.inrPageTPAdmin button.btn-outline-primary:hover,
.inrPageTPAdmin button.btn-outline-primary:focus {
    border-color: #00355B;
    background-color: #00355B;
    color: #ffffff;
}
.dashTitleBox .btn {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
}

.inrPageTPAdmin button.btn-sm {
    padding-left: 12px;
    padding-right: 12px;
    line-height: 22px;
}
.btn-sm svg {
    width: 16px;
    margin-right: 4px;
    display: inline-block;
    vertical-align: middle;
    transform: translateY(-1px);
}

.cListTable {
    overflow: auto;
    height: calc(100vh - 200px);
    position: relative;
}
.cListTable .cTable {
    min-width: 1100px;
}
.cListTable .cTable.w1350 {
    min-width: 1350px;
}
.cTable {
    position: relative;
}
.cTable thead tr {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 99;
}
.cTable th {
   color: #00355B;  
   padding: 1rem;
   border: none;   
   text-align: left;
}
.cTable tbody tr {
    border-style: solid;
    border-width: 5px 0;
    border-color: #ffffff;
}
.cTable tbody td {
    padding: 0.5rem 1rem;
    background-color: #F7F8FA;
}
.cTable tbody tr td:first-of-type {
    -webkit-border-radius: 1rem 0 0 1rem;
    border-radius: 1rem 0 0 1rem;
}
.cTable tbody tr td:last-of-type {
    -webkit-border-radius: 0 1rem 1rem 0;
    border-radius: 0 1rem 1rem 0;
    text-align: right;    
}
.cTable.table tr td {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: none;
    color: #7C8AA5;
    line-height: 1.5;
    vertical-align: middle;
    text-align: left;
}
td .form-check-input {
    margin: 0;
    vertical-align: middle;
}

.avatar {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: inline-block;
    vertical-align: middle;
    width: 36px;
    height: 36px;
    background-image: url('../../images/img_avatar2.png');
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}

.actStat {
    display: inline-block;
    vertical-align: middle;
    margin: 4px 8px;
    height: 36px;
    line-height: 30px;
    position: relative;
    padding: 0 16px 0 36px;
    -webkit-border-radius: 50rem;
    background-color: #ffffff;
    background-image: none;
    border-radius: 50rem;
    border: solid 1px transparent;
    text-align: left;
}
.actStat::before {
    content: '';
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 4px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.actStat.approve,
.actStat.approved {
    color: #4CAF50;
}
.actStat.approve::before,
.actStat.approved::before {
    background-image: url('../../images/svg/checked-circle-green.svg');
}
.actStat.approved {
    border-color: #4CAF50;
    background-color: #E4FFE5;
}

.actStat.reject,
.actStat.rejected {
    color: #F24822;
}
.actStat.reject::before,
.actStat.rejected::before {
    background-image: url('../../images/svg/cancel-circle-red.svg');
}
.actStat.rejected {
    border-color: #F24822;
    background-color: #FFE8E2;
}

.delBtn,
.viewBtn {
    display: inline-block;
    vertical-align: middle;
    margin: 4px 8px;
    background-color: transparent;
    border: none;
    outline: none;
    background-repeat: no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
}
.delBtn {
    background-image: url('../../images/svg/trash-primary.svg');
}
.viewBtn {
    background-image: url('../../images/svg/eye-primary.svg');
}

.timeStamp {
    position: absolute;
    top: calc(100% + 60px);
    left: 0;
    border-bottom: solid 1px #cfcfcf;
    width: 100%;
    z-index: 999;
}
.timeStamp span {
    background-color: #ffffff;
    padding: 6px 12px;
    position: absolute;
    top: -20px;
    right: 14px;
    display: inline-block;
    font-size: 14px;
    color: #7C8AA5;
}

.hdrTitle .actStat {
    margin-left: 0;
    margin-right: 0;
    line-height: 1;
}
.hdrTitle .actStat.approve {
    border-color: #4CAF50;
}
.hdrTitle .actStat.reject {
    border-color: #F24822;
}
.hdrTitle .btn {
    -webkit-border-radius: 50rem;
    border-radius: 50rem;
}

.tabsWrap,
.contRightWrap {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 230px);
    padding-right: 1rem;
    padding-left: 0.2rem;
}
.tabsWrap .nav-tabs {
    border-bottom: solid 1px #b5b5b5;
}
.tabsWrap .nav-tabs .nav-item {
    margin-right: 1em;
}
.tabsWrap .nav-tabs .nav-link {
    color: #555555;
    font-family: 'Work Sans', sans-serif;
    border-style: solid;
    border-width: 0 0 3px;
    border-color: transparent;
    font-weight: 500;
}
.tabsWrap .nav-tabs .nav-link.active {
    border-bottom-color: #00355B;
    font-weight: 700;
}

.proAvatarUpload {
    width: 192px;
    height: 192px;
    position: relative;
    -webkit-border-radius: 32px;
    border-radius: 32px;
    background-repeat: no-repeat;
    background-color: #ffffff;
    background-size: cover;
    background-position: center;
    background-image: url('../../images/avatar-png-profile-icon.png');
}
button.uploadBtn {
    position: absolute;
    right: -12px;
    bottom: -12px;
    z-index: 4;
    width: 48px;
    height: 48px;
    background-color: #ffffff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: center;
    background-image: url('../../images/svg/uploadPro-ico.svg');
    border: none;
    outline: none;
}
button.uploadBtn input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    opacity: 0;
}
.formDetails .form-label {
    font-weight: 400;
}
.formDetails .form-control,
.formDetails .form-select {
    border-color: transparent;
    background-color: #F2F2F7;
    color: #00355B;
    font-weight: 600;
}

.tabsWrap .footBtn {
    border-top: solid 1px #C7D2D6;
    text-align: right;
    margin-top: 1em;
}

.btn.btn-primary span.fs-4 {
    line-height: 16px;
}
.annIco {
    width: 48px;
    height: 48px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain; 
    vertical-align: middle; 
    -webkit-border-radius: 50%; 
    border-radius: 50%; 
}
.annIco.mic {
    background-image: url('../../../public/svg/announce-ico.svg');
}
.annIco.speaker {
    background-image: url('../../../public/svg/announce-ico-speaker.svg');
}
.annIco.scholar {
    background-image: url('../../../public/svg/announce-ico-scholar.svg');
}
.annIco.brainstorm {
    background-image: url('../../../public/svg/announce-ico-brainstorm.svg');
}
.annIco.intern {
    background-image: url('../../../public/svg/announce-ico-interns.svg');
}
.annIco.program {
    background-image: url('../../../public/svg/announce-ico-program.svg');
}


.announce .cTable th {
    color: #737373;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}
.announce.cListTable .cTable {
    min-width: 0;
}
.cstCheckFld {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 40px;
    height: 20px;
}
.cstCheckFld > input,
.cstCheckFld > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50rem;
    border-radius: 50rem;
    cursor: pointer;
}
.cstCheckFld > input {
    z-index: 4;
    opacity: 0;
}
.cstCheckFld > label {
    background-color: #d9d9d9;
    z-index: 1;
}
.cstCheckFld > label::before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    position: absolute;
    left: 2px;
    top: 2px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.cstCheckFld > input:checked + label {
    background-color: #2C6ECB;
}
.cstCheckFld > input:checked + label::before {
    left: auto;
    right: 2px;
}
.announce .dropdown-toggle.btn::after {
    display: none !important;
}
.announce .dropdown-toggle.btn.btn-primary {
    background-color: transparent;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: none;
    text-align: center;
    color: #00355B;
    padding: 0;
}
.announce .show .dropdown-toggle.btn.btn-primary {
    background-color: #00355B;
    color: #ffffff;
}
.announce .dropdown-menu {
    border: none;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}
.announce .dropdown-menu .dropdown-item {
    color: #000000;
    margin: 2px 0;
}
.announce .dropdown-menu .dropdown-item:focus, 
.announce .dropdown-menu .dropdown-item:hover {
    background-color: #E9F0FF;
    color: #00355B !important;
}
.announce .dropdown-menu .dropdown-item dl {
    overflow: hidden;
    margin: 0;
}
.announce .dropdown-menu .dropdown-item dl dt {
    float: left;
    width: 30px;
}
.announce .dropdown-menu .dropdown-item dl dd {
    margin-left: 40px;
    font-weight: 600;
}
.announce .dropdown-menu .dropdown-item .fs-6 {
    font-size: 12px !important;
    font-weight: 400;
}

.editAnnouncement .form-control,
.editAnnouncement .form-select {
   background-color: #F2F2F7;
}
.chooseIcon {
    position: relative; 
    width: 62px;
    height: 62px;  
    display: inline-block; 
}
.chooseIcon .annIco {
    background-image: none !important;
    position: relative;    
}
.chooseIcon .annIco::before {
    content: '';
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; 
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 21px;
    height: 21px;
}
.chooseIcon .annIco.mic::before {
    background-image: url('../../../public/svg/announce-mic.svg');    
}
.chooseIcon .annIco.speaker::before {
    background-image: url('../../../public/svg/announce-speaker.svg');    
}
.chooseIcon .annIco.scholar::before {
    background-image: url('../../../public/svg/announce-scholar.svg');
}
.chooseIcon .annIco.brainstorm::before {
    background-image: url('../../../public/svg/announce-brainstorm.svg');
}
.chooseIcon .annIco.intern::before {
    background-image: url('../../../public/svg/announce-intern.svg');
}
.chooseIcon .annIco.program::before {
    background-image: url('../../../public/svg/announce-program.svg');
}
.chooseIcon > input,
.chooseIcon > .annIco {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.chooseIcon > input {
    z-index: 4;
    opacity: 0;
    cursor: pointer;
}
.chooseIcon > .annIco {
    background-color: #F2F2F7;
    z-index: 1;
}
.chooseIcon > input:checked + .annIco {
    background-color: #00355B;    
}
.chooseIcon > input:checked + .annIco::before {
    -webkit-filter: grayscale(1) brightness(100); 
    filter: grayscale(1) brightness(100);  
}

.inrPageTPAdmin .col-lg-3.bdrR {
    border-right: solid 2px #E9EBF0;
}
.w80 {
    width: 80px;
}
.btn.btn-sm svg {
    width: 16px;
    margin: 0 6px 0 0;
}
.cListTable .btn.btn-white {
    background-color: #ffffff;
    border-color: #E9EBF0;
    color: #00355B !important;
}
.btnBrowse {
    display: inline-block;
    position: relative;
    background: #F8F8FF;
    border: 1px dashed rgba(56, 78, 183, 0.3);
    -webkit-border-radius: 8px;
    border-radius: 8px;
    min-width: 230px;
    padding: 10px 10px 10px 90px;
}
.btnBrowse > svg {
    position: absolute;
    left: 16px;
    top: 50%;
    z-index: 1;
    font-size: 48px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.btnBrowse > input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
}
.btnBrowse label {
    font-weight: 700;
}
.btnBrowse .small {
    font-size: 10px;
    color: rgba(0,0,0,0.5);
}
.uploadedMedia {
    background: #F8F8FF;
    border: 1px dashed rgba(56, 78, 183, 0.3);
    -webkit-border-radius: 8px;
    border-radius: 8px;
    padding: 10px;
    margin-top: 1.5em;
}
.uploadedMedia .gItem {
    position: relative;
}
button.delMediaBtn {
    width: 22px;
    height: 26px;
    background-color: #ffffff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    top: -2px;
    right: -2px;
    z-index: 2;
    border: none;
    outline: none;
}
.uploadedMedia {
    max-height: 248px;
    overflow-x: hidden;
    overflow-y: auto;
}

.dashStatBox {
    background-color: rgba(0, 53, 91, 1);
    color: #ffffff;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -webkit-filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.25));
}
.dashStatBox .hdr {
    height: 58px;
    background-color: #E9EFFD;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../../public/svg/dashStyleHdr.svg');
    padding: 17px 24px 0;
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 53, 91, 1);
    position: relative;
    -webkit-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
}
.dashStatBox .hdr span {
    display: inline-block;
    position: absolute;
    right: 24px;
    top: 50%;
    font-size: 30px;
    font-weight: 700;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.dBody {
    position: relative;
}
.dBody::after {
    content: '';
    width: 1px;
    height: 100%;
    background-color: rgba(1, 42, 70, 1);
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 0;
}
.dBody .btn.btn-white {
    padding: 4px 8px;
    font-size: 14px;
}
.dtW {
    width: 170px;
}
.app .MuiFormControl-root .MuiInputBase-input {
    padding: 7px 0 7px 12px;
    line-height: 1.8;
}
.inrPageTPAdmin .input-group.dtW {
    border: 1px solid #ced4da;
}
.inrPageTPAdmin .input-group.dtW .MuiFormControl-root .MuiInputBase-input,
.inrPageTPAdmin .input-group.dtW .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
    border: 0;
}
button.backBtn {
    background-color: transparent;
    background-image: none;
    border: none;
    outline: none;
    padding: 0;
    font-size: 32px;
    color: #000000;
    position: absolute;
    left: 16px;
    top: 5px;
}


/* === Media Query ==== */

@media (max-width: 767px) {
    body .inrPageTPAdmin {
        padding-top: 222px;
    }
    body a.navbar-brand {
        display: block;
        width: 100px;
        margin: auto;
    }
}

@media (max-width: 991px) {
    .cListTable .cTable {
        min-width: 1100px;
    }
}

@media (min-width: 992px) {
    .adminSideNav {
        height: calc(100vh - 126px);
     }
}