.header__top {
    display: flex;
    padding-top: 9px;
    margin-right: 0;
    justify-content: end;
    font-size: 95%;
}

.social {   
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 20px;
}