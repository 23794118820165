.header {
    display: block;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;   
    background-color: #ffffff; 
}
.header nav {
    background-color: transparent;
}
.header.scrolled,
.whiteHdr .header {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.6);
    box-shadow: 0 0 8px rgba(0,0,0,0.6);
}

.logo {    
    width: 99px;
    height: auto;
    margin: auto;    
}
.header__bottom {
    width: fit-content;
    font-size: 16px;
    font-family: sans-serif;
    padding-top: 70px;
    padding-left: 70px;
    display: flex;
    column-gap: 30px;
}

.header__complete {
    display: flex;
}

.headerbtns {
    border: none;
    text-decoration: none;
    text-align: left;
    outline: none;
    color: black;
    font-weight: 700;
    font-size: 16px;
    white-space: nowrap;
    background-color: transparent;
    cursor: pointer;
}

.header a {
    text-decoration: none;
    color: #000000;
}

.header__top p a {
    font-weight: 500;
    color: #00355B;
}
.header .nav-item {
    font-size: 95%;
}
.nav-item .ddItem {
    display: inline-block;
    position: relative;
}
.header .nav-item .ddItem .nav-link {
    padding-right: 30px !important;
}
.nav-item .ddItem > button.btnCaret {
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0;
    background-color: transparent;
    background-image: none;
    border: none;
    outline: none;   
    height: 40px; 
}
.ddMenuItems {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 180px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 9;
    background-color: #ffffff;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 0 6px rgba(0,0,0,0.5);
    box-shadow: 0 0 6px rgba(0,0,0,0.5);
}
.ddMenuItems > a {
    display: block;
    padding: 6px 14px;
    color: #00355B;
    text-align: left;
    border-top: solid 1px #c2c2c2;
}
.ddMenuItems > a:hover,
.ddMenuItems > a:focus {
    background-color: #00355B;
    color: #ffffff;
}
.ddMenuItems > a:first-of-type {
    -webkit-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0;
    border-top: none;
}
.ddMenuItems > a:last-of-type {
    -webkit-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px;
}

button.navbar-toggler:not(.collapsed) span.navbar-toggler-icon {
    /* Used base64 image - free */
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cGF0aCBkPSJNNDM3LjUsMzg2LjZMMzA2LjksMjU2bDEzMC42LTEzMC42YzE0LjEtMTQuMSwxNC4xLTM2LjgsMC01MC45Yy0xNC4xLTE0LjEtMzYuOC0xNC4xLTUwLjksMEwyNTYsMjA1LjFMMTI1LjQsNzQuNSAgYy0xNC4xLTE0LjEtMzYuOC0xNC4xLTUwLjksMGMtMTQuMSwxNC4xLTE0LjEsMzYuOCwwLDUwLjlMMjA1LjEsMjU2TDc0LjUsMzg2LjZjLTE0LjEsMTQuMS0xNC4xLDM2LjgsMCw1MC45ICBjMTQuMSwxNC4xLDM2LjgsMTQuMSw1MC45LDBMMjU2LDMwNi45bDEzMC42LDEzMC42YzE0LjEsMTQuMSwzNi44LDE0LjEsNTAuOSwwQzQ1MS41LDQyMy40LDQ1MS41LDQwMC42LDQzNy41LDM4Ni42eiIvPjwvc3ZnPg==');
}
.header .navbar .container-xl {
    max-width: 1320px;
} 



@media (min-width: 992px) and (max-width: 1300px) {
    .header .nav-item {
        font-size: 85%;
    }
}

@media (min-width: 1400px) {
    body .header .nav-item .nav-link {
        padding-bottom: 12px;
        border-bottom: solid 3px transparent;
        line-height: 26px;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}

@media (min-width: 1200px) {
    .header nav.navbar-expand-lg {
        padding-bottom: 0;
    }
    .header .nav-item {
        padding-left: 0.5em;
        padding-right: 0.5em;
    }
    .header .nav-item:last-of-type,
    .header .nav-item:last-of-type .nav-link {
        padding-right: 0;
    }
    .header .nav-item .nav-link {
        padding-bottom: 12px;
        border-bottom: solid 3px transparent;
        line-height: 26px;
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
    .header .nav-item .nav-link.active {
        color: #00355B;
        font-weight: 700;
        border-bottom-color: #00355B;
    }
    .header .nav-item .nav-link2 {
        display: block;
        padding: 8px 8px 12px;
        border-bottom: solid 3px transparent;
    }
}

@media (max-width: 1199px) {
    .header .navbar-toggler {
        position: absolute;
        top: 50%;
        right: 15px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        height: 50px;
    }
    .header .navbar-collapse {
        position: fixed;
        width: 100%;
        z-index: 999;
        left: 0;
        top: 112px;
        height: calc(100vh - 100px);
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #f0f0f0;
        padding: 1em;
    }
    .header .navbar-collapse .header__top,
    .header .navbar-collapse .header__top .social,
    .header .navbar-collapse .nav {
        display: block;
        text-align: center;
    }
    .header .navbar-collapse .header__top p,
    .header .navbar-collapse .header__top .social a {
        display: inline-block;
    }
    .header .navbar-collapse .header__top .social {
        padding-top: 10px;
    }
    .header .navbar-collapse .header__top .social a {
        margin: 0 10px;
    }
    .header .navbar-collapse .nav {
        padding-top: 1em;
    }
    .header .navbar-collapse .header__top p.pe-4 {
        padding-left: 1em !important;
        padding-right: 1em !important;
    }
    .header .nav-item .nav-link.active {
        color: #00355B;
        font-weight: 700;
    }
}