.admissions {
    background-color: #FBFBFF;
    padding-bottom: 4em;
    padding-top: 4em;
    /* border-bottom: solid 3px #E9E9FD; */
}

.headingAdmission {
    text-align: center;
    color: #00355B;
}

.flex-column.nav-pills {
    border-right: solid 1px #00355B;
}