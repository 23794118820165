@import url('//fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&family=Work+Sans:wght@500;700&family=Inter:wght@400;600;800&display=swap');

:root {
    font-family: 'Open Sans', sans-serif;
    /* scrollbar-color: #999999 #f1f1f1;
    scrollbar-width: thin; */
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}   
::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}   
::-webkit-scrollbar-thumb {
    background-color: #999999;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
}

.vTabs {
    position: relative;    
}
.vTabs .nav-item {
    margin-bottom: 1em;
}
.vTabs .nav-item:last-of-type {
    margin-bottom: 0;
}
.vTabs a.nav-link {
    background-color: transparent;
    border: solid 1px #ffffff;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    padding: 1em 1.5em;
    color: #ffffff;
}
.vTabs a.nav-link.active {
    background-color: rgba(246, 128, 35, 1);
    border-color: rgba(246, 128, 35, 1);
}
.btn {
    font-weight: 500;
}
.btn.btn-white {
    background-color: #ffffff;
    border: solid 1px #ffffff;
    color: rgba(0, 53, 91, 1);
}
.btn.btn-white:hover,
.btn.btn-white:focus {
    background-color: transparent;
    color: #ffffff;
}

.btn.btn-primary {
    background-color: #00355B;
    border-color: #00355B;
    color: #ffffff;
}
.btn.btn-primary:hover,
.btn.btn-primary:focus {
    background-color: transparent;
    color: #00355B;
}

.subpageBanner {
    background-color: #d9d9d9;
    background-size: cover;
    background-position: 0 0;
    overflow: hidden;
    position: relative;
    padding: 40% 0 8%;
}
.subpageBanner h1 {
    font-size: 2.5em;
    color: #ffffff;
}
.app .admission {
    background-image: none;
}
.contLayout {
    border-bottom: solid 2px #f0f0f0;
}
.contLayout p {
    line-height: 1.8;
    font-weight: 400;
}
.bgBlue {
    background-color: #00355B;
}
.contLayout h2 {
    color: #00355B;
    margin-bottom: 1.6em;
}
.contLayout.bgBlue h2,
.contLayout.bgBlue p {
    color: #ffffff;
}
.contLayout img {
    max-width: 100%;
}
.contLayout strong {
    font-weight: 700;
}
.contLayout li {
    margin: 6px 0;
}

.inrPageTopPad {
    padding-top: 113px;
}
.bgLight {
    background-color: #FBFBFF;
}

.inrPageTopPad .inrPageTopPad {
    padding: 0;
}
.filterBar {
    background-color: #00355B;
}

@media (min-width: 992px) {
    .vTabs::after {
        content: '';
        width: 1px;
        height: 100%;
        background-color: #ffffff;
        position: absolute;
        left: calc(25% - 0.5em);
        top: 0;
    }
    .subpageBanner {
        padding: 15% 0 5%;
    }
    .subpageBanner h1 {
        font-size: 4em;
    }
}

/* Slider's styles */

.contLayout .slick-slider {
    padding-left: 2em;
    padding-right: 2em;
}
.slick-slider .slick-prev:before, .slick-slider .slick-next:before {
    display: none;
}
.slick-slider .slick-prev, .slick-slider .slick-next {
    width: 14px;
    height: 22px;
    background-repeat: no-repeat !important;
    background-size: contain !important;    
}
.slick-slider .slick-prev {
    background-image: url('../images/svg/left-arrow-dark.svg') !important;
    left: 0;
}
.slick-slider .slick-next {
    background-image: url('../images/svg/right-arrow-dark.svg') !important;
    right: 0;
}
.slick-slider .slick-slide {
    padding: 0 10px;
}
.slick-slider .slick-disabled {
    opacity: 0.2;
    cursor: not-allowed;
}

.whoAre .slick-slide div img {
    height: 180px;
    width: 100%;
    object-fit: cover;
}

.objBox {
    position: relative;
    border: solid 1px #00355B;
    padding: 2em;
    height: 100%;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    text-align: left;
}
.objCounter {
    counter-reset: objects;
}
.objCounter .col-sm-6 {
    counter-increment: objects;
}
.objCounter .col-sm-6 .objBox::before {
    content: '';
    width: 48px;
    height: 48px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../public/svg/circle-outer-art.svg');
    background-position: bottom center;
    position: absolute;
    left: 25px;
    top: -26px;
    z-index: 2;
    -webkit-filter: drop-shadow(0 0 1px #ffffff);
    filter: drop-shadow(0 0 1px #ffffff);
}
.objCounter .col-sm-6 .objBox::after {
    content: counter(objects);
    width: 34px;
    height: 34px;
    position: absolute;
    left: 32px;
    top: -19px;
    z-index: 2;
    background-color: #ffffff;
    border: solid 1px #00355B;
    -webkit-border-radius: 50%;   
    border-radius: 50%; 
    background-color: #ffffff;
    text-align: center;
    font-size: 18px;
    color: #00355B; 
    line-height: 30px;   
}
.objCounter .col-sm-6:nth-child(even) .objBox,
.objCounter .col-sm-6:nth-child(even) .objBox::after {
    background-color: #00355B;
    color: #ffffff;
}

.whiteTh .vTabs .nav-item {
    padding-left: 10px;
}
.whiteTh .vTabs a.nav-link {
    background-color: transparent;
    border: solid 1px #ffffff;
    -webkit-border-radius: 0;
    border-radius: 0;
    padding: 1em 1.5em;
    color: #00355B;
    position: relative;
}
.whiteTh .vTabs a.nav-link.active {
    background-color: #00355B;
    border-color: #00355B;
    color: #ffffff;
}
.whiteTh .vTabs a.nav-link.active::before {
    content: '';
    position: absolute;
    left: -10px;
    top: -1px;
    height: calc(100% + 2px);
    width: 7px;
    background-color: #00355B;
    -webkit-border-radius: 50rem;
    border-radius: 50rem;
}

.btnGroup {
    position: relative;
    display: inline-block;
    margin: 0 1rem 8px;
}
.btnGroup + .btn {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
}
.btnGroup .btn {
    margin-bottom: 8px;
}
.btnGroup .hvrDD {
    opacity: 0;
    visibility: hidden;
    background-color: #00355B;
    border: none;
    padding: 6px 0;
    position: absolute;
    left: 50%;
    top: 100%;
    -webkit-transform: translate(-50%, 20px);
    transform: translate(-50%, 20px);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    min-width: 180px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
}
.btnGroup .hvrDD::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #00355B;
    position: absolute;
    z-index: 3;
    left: 50%;
    top: -6px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.btnGroup:hover .hvrDD {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}
.btnGroup .hvrDD .btn {
    display: block;
    width: 100%;
    margin: 0;
    border-radius: 0;
    position: relative;
}
.btnGroup .hvrDD .btn:hover {
    color: #ffffff !important;
    background-color: rgba(255,255,255,0.10);
}
.btnGroup .hvrDD .btn input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    opacity: 0;
}
.btnGroup .hvrDD .btn.bdrB {
    border-bottom: solid 1px rgba(255,255,255,0.3);
}

body .text-primary {
    color: #00355B !important;
}

.vAlign {
    vertical-align: middle;    
}


/* Inner page - Admission */

.afterBanForm {
    position: relative;    
}
.afterBanForm::after {
    content: '';
    width: 100%;
    height: 70%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    background-color: #00355B;
}
.afterBanForm .container-lg,
.whiteBG .container-lg {
    position: relative;
    z-index: 1;
}
.afterBanForm .formBox {
    padding: 2em;
    background-color: #ffffff;
    -webkit-border-radius: 22px;
    border-radius: 22px;
    -webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}
.afterBanForm .formBox .form-control,
.afterBanForm .formBox .form-select {
    border-width: 0 0 1px;
    border-color: #a9a9a9;
    color: #00355B;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.whiteBG {
    background-color: #ffffff;
    position: relative;
}
.whiteBG::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    background-color: #F6F7F9;
}

h1.leftBdr {
    color: #00355B;
}

ul.programs,
ul.programs li {
    list-style: none;
    margin: 0;
}
ul.programs {
    padding: 0;
}
ul.programs li {
    padding: 0.5em;
    width: 100%;
}
ul.programs li .listBox {
    background-color: #ffffff;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    padding: 2em;
    color: #00355B;
    font-size: 12px;
}
ul.programs li:nth-child(1) .listBox {
    background-color: #00355B;
    color: #ffffff;
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.45);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.45);
}
ul.programs li .listBox .btn-outline-primary {
    border-color: #00355B;
    color: #00355B;
}
ul.programs li:nth-child(1) .listBox .btn-outline-primary,
ul.programs li:nth-child(1) .listBox .btn-outline-secondary {
    border-color: #ffffff;
    color: #ffffff;
}
ul.programs li:nth-child(1) .listBox .btn-primary {
    background-color: #ffffff;
    color: #00355B;
}
ul.programs li:nth-child(1) .listBox .btn-primary:hover,
ul.programs li:nth-child(1) .listBox .btn-primary:focus {
    background-color: transparent;
    color: #ffffff;
    border-color: #ffffff;
}
ul.programs li:nth-child(1) .listBox .hvrDD::before {
    border-bottom-color: #13181b;
}
ul.programs li:nth-child(1) .listBox .hvrDD {
    background-color: #13181b; 
}
ul.programs li:nth-child(1) .listBox .hvrDD .btn-primary {
    background-color: #13181b;
    color: #ffffff;   
    border-color: transparent; 
}
ul.programs li:nth-child(1) .listBox .hvrDD .btn-primary.bdrB {
    border-bottom-color: rgba(120, 120, 120, 0.5);
}
ul.programs li:nth-child(1) .listBox .hvrDD .btn-primary:hover,
ul.programs li:nth-child(1) .listBox .hvrDD .btn-primary:focus {
    background-color: rgba(120, 120, 120, 0.2);
    color: #ffffff;    
}
ul.programs li .listBox .btn-outline-primary:hover,
ul.programs li .listBox .btn-outline-primary:focus {
    background-color: transparent;
}
.hTabs .nav-tabs {
    border: none;
}
.hTabs .nav-tabs .nav-item {
    margin-right: 1.5em;
}
.hTabs .nav-tabs .nav-item .nav-link {
    background-color: transparent;
    border-width: 0 0 3px;
    border-color: transparent;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: #00355B;
}
.hTabs .nav-tabs .nav-item .nav-link.active {
    background-color: #EEF8FF;
    border-color: #00355B;
    font-weight: 700;
}
.hTabs .tab-content {
    padding: 2em 0;
}
.hTabs .mwImgCont {
    max-width: 900px;
    margin: 0 auto 4em;
}

.nameLbl {
    background-color: #EEF8FF;
    padding: 6px 14px;
    display: block;
}

.afterBanForm .logTxtDnger {
    display: block;
    font-size: 12px;
    text-align: left;
    color: #cf0000;
    padding: 0.2em .75rem 0;
}

.navyBanner {
    position: relative;
    background-color: #00355B;
    color: #ffffff;
    font-size: 24px;
    line-height: 1.2;
    overflow: hidden;
}
.navyBanner .small {
    font-size: 16px;
    line-height: 1.3;
}
.navyBanner .bannerWrap {
    max-width: 830px;
    padding: 0 50px;
    margin: 0 auto;
}
.navyBanner .bannerWrap.lg {
    max-width: 1080px;
}
.navyBanner::before,
.navyBanner::after {
    content: '';
    width: 100px;
    height: 110px;
    position: absolute;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.navyBanner::before {
    left: -20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background-image: url('../../public/admissions/banner-left-float.svg');
}
.navyBanner::after {
    right: -20px;
    top: 10%;
    background-image: url('../../public/admissions/banner-right-float.svg');
}
.navyBanner .btn.btn-primary {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #00355B;
    line-height: 32px;
}
.navyBanner .btn.btn-primary:hover,
.navyBanner .btn.btn-primary:focus {
    background-color: #999999;
    border-color: #999999;
    color: #ffffff;
}
.infoGraph1 .container-lg {
    max-width: 900px;
    margin: 0 auto;
}
.topCrop {
    overflow: hidden;
    margin: 0 0 2em;
}
.topCrop img {
    margin-top: -50px;
}
.grayBoxTB {
    background-color: #FBFBFF;
    border-style: solid;
    border-width: 3px 0;
    border-color: #E9E9FD;
}
.selection .selBox {
    background: #FFFFFF;
    box-shadow: -10px 10px 25px rgba(0, 0, 0, 0.15);
    height: 100%;
    min-height: 200px;
    text-align: left;
}
.selection .selBox .hdr {
    background-color: #00355B;
    font-size: 34px;
    font-weight: 700;
    color: #ffffff;
    line-height: 1.2;
}
.selection .selBox .hdr span {
    display: block;
    font-size: 13px;
    font-weight: 400;
}
.selection > .col-lg {
    position: relative;
}
.selection > .col-lg::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #00355B;
    position: absolute;
    top: 1.5em;
    right: 0;
}
.selection > .col-lg::after {
    content: '';
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border: solid 6px #00355B;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    top: 1.1em;
    right: 1em;
}
.selection > .col-lg:first-of-type::before {
    width: 1em;
}
.selection > .col-lg:last-of-type::before {
    width: calc(100% - 1em);
    right: 1em;
}

ul.onlineSteps,
ul.onlineSteps li {
    list-style: none;
    margin: 0;
    padding: 0;
}
ul.onlineSteps {
    counter-reset: oSteps;
}
ul.onlineSteps li {
    counter-increment: oSteps;
    position: relative;
}
ul.onlineSteps li::before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #00355B;
    position: absolute;
    top: -12px;
    left: 0;
}
ul.onlineSteps li::after {
    content: counter(oSteps);
    width: 38px;
    height: 38px;
    background-color: #00355B;
    color: #ffffff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    position: absolute;
    left: 0;
    top: -30px;
    z-index: 1;
}
ul.onlineSteps li:last-child::before {
    width: 0;
}
.uLStyle {
    padding-bottom: 4px;
    border-bottom: solid 1px #00355B;
}
.uLStyle span {
    font-size: 150%;
    display: inline-block;
    line-height: 1;
}
.mw800p {
    max-width: 900px;
    padding: 0 1em;
    margin: 0 auto;
}
.mw1000p {
    max-width: 1000px;
    padding: 0 1em;
    margin: 0 auto;
}
.bdrA {
    border: solid 1px #00355B;
}
.text-yellow {
    color: #FFEA13;
}
.highLClassBox {
    max-width: 834px;
    margin: 0 auto;
}
.highLClassBox .styleBox {
    text-align: center;
    padding: 2.5em 2em;
    position: relative;
}
.highLClassBox .styleBox::before {
    content: '';
    width: 70px;
    height: 12px;
    background-color: #00355B;
    position: absolute;
    left: 0;
    top: 0;
}
.highLClassBox .styleBox p span {
    font-size: 150%;
    font-weight: 600;
    display: inline-block;
    padding-left: 4px;
} 
.highLClassBox .styleBox.sbOne {
    background: #ECF1F4;
    -webkit-box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.25);
}
.highLClassBox .styleBox.sbTwo {
    background: #D0DFE9;
    -webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.08);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.08);
}
.text-justify {
    text-align: justify;
}
.text-white {
    color: #ffffff !important;
}
.twoFold {
    position: relative;
}
.mw360p {
    max-width: 360px;
    margin: auto;
}
.mw1080p {
    max-width: 360px;
    margin: auto;
}
.hrCaption {
    border-style: solid;
    border-width: 2px 0 0;
    border-color: #00355B;
    opacity: 1;
    position: relative;
    margin: 1em 0 2.5em;
}
.hrCaption > span {
    position: absolute;
    left: 0;
    top: -22px;
    padding: 6px 12px 6px 0;
    background-color: #ffffff;
    z-index: 1;
    color: #00355B;
    font-weight: 700;
}
.bsBox {
    position: relative;
    padding: 2em 1em 1em;
    height: 100%;
    -webkit-box-shadow: inset -10px 10px 30px rgba(0, 0, 0, 0.3);
    box-shadow: inset -10px 10px 30px rgba(0, 0, 0, 0.3);
    color: #ffffff;
    font-weight: 300;
    font-size: 34px;
}
.bsBox .topLine {
    margin-top: -140px;
    font-weight: 700;
    font-size: 74px;
    letter-spacing: -5px;
    line-height: 112px;
    color: #00355B;
    text-shadow: 0px 4px 0px rgba(255, 255, 255, 0.3), 0px 30px 30px rgba(0, 0, 0, 0.5);
}
.topLine + .fs-2 {
    margin-top: -18px;
}
.bsBox.bsOne {
    background-color: #00355B;
}
.bsBox.bsTwo {
    background-color: #004475;
}
.bsBox.bsThree {
    background-color: #045086;
}
.bsBox.bsFour {
    background-color: #155F93;
}
.table-responsive.outerBdr {
    border: solid 1px #00355B;    
}
.table-responsive.outerBdr .table {
    margin: 0;
}
.table.lstCellBdr thead th:last-child,
.table.lstCellBdr tbody td:last-child {
    border-left: solid 1px #00355B;
}
.row.bdrV .col-12:first-of-type {
    border-right: solid 1px #00355B;
}

/* Log Side Panel */
.logUI .header {
    display: none;
}
.sideBoxLog {
    width: 100%;
    min-height: 500px;
    position: relative;
    background-color: #424242;
    background-image: url('../../public/log/logBanner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.sideBoxLog .floatedLogo {
    position: absolute;
    width: 264px;
    height: 222px;
    background-repeat: no-repeat;
    background-size: contain;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 9;
    background-image: url('../../public/svg/logo-white.svg');
}
.copyStamp {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    width: calc(100% - 2em);
    z-index: 3;
    right: 1em;
    bottom: 1em;
    text-align: center;
}
.media .nav-tabs,
.media .tab-content .tab-pane {
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 1320px;
    margin: 0 auto;
}
.media .nav-tabs {
    margin-bottom: 2rem;
}
.media .tab-content {
    background-color: #00355B;
    padding: 2em 0;
    color: #ffffff;
}
.media .slick-dots {
    bottom: -35px;
    padding: 0 60px;
}
.media .slick-dots li {
    margin: 0;
}
.media .slick-dots li button:before,
.media .slick-dots li.slick-active button:before {
    color: #ffffff;
}
.media .thumbs .slick-slider .slick-arrow.slick-prev, 
.media .thumbs .slick-slider .slick-arrow.slick-next {
    top: -26px;
    -webkit-filter: grayscale(1) brightness(100);
    filter: grayscale(1) brightness(100);
}
.media .thumbs .slick-slider .slick-arrow.slick-prev {
    left: 30px;
}
.media .thumbs .slick-slider .slick-arrow.slick-next {
    right: 30px;
}
.contForm .mb-4 > label {
    display: block;
    margin-bottom: 8px;
}
.contForm .txtFld {
    width: 100%;
    padding: 0 0 4px;
    border-style: solid;
    border-width: 0 0 3px;
    border-color: #000000;
    outline: none;
    background-color: transparent;
    background-image: none;
    font-size: 14px;
}
h1.contactTitle {
    font-size: 62px;
    color: #00355B;
}
.conactInfo a {
    text-decoration: none;
    color: #000000;
}
.conactInfo .social svg {
    font-size: 38px;
}
.titleBanner {
    background-color: #424242;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #ffffff;
}
.titleBanner.donation {
    background-image: url('../../public/donation/banner.jpg');
}
.titleBanner.bgBlue {
    background-color: #00355B;
}
.donations .nav.nav-tabs {
    justify-content: center;
    border-bottom: solid 1px #B5B5B5;
}
.contLayout .donations li {
    margin-bottom: 0;
}
.hTabs.donations .nav-tabs .nav-item {
    padding: 0 6px;
    position: relative;
}
.hTabs.donations .nav-tabs .nav-item .nav-link.active {
    background-color: transparent;
}
.hTabs.donations .nav-tabs .nav-item::after {
    content: '';
    width: 1px;
    height: 24px;
    background-color: #555555;
    position: absolute;
    top: 50%;
    right: -6px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.hTabs.donations .nav-tabs .nav-item:last-of-type::after {
    display: none;
}
.statInfoBtn button.btn {
    width: 100%;
    height: 100%;
    display: block;
    padding: 1em;
}
.inrPageTopPad.statsInfo .popOuter,
.home .popOuter,
.popOuter.indv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.9);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    z-index: 999;
}
.inrPageTopPad.statsInfo .popIn,
.home .popIn,
.popOuter.indv .popIn {
    background-color: #ffffff;
    -webkit-border-radius: 12px;
    border-radius: 12px;    
    position: absolute;
    width: 90%;
    max-width: 1320px;
    padding: 1.5em;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);    
}
.inrPageTopPad.statsInfo .popIn .popInCont,
.home .popIn .popInCont {        
    height: calc(90vh - 3em);
    overflow: auto;
}
.inrPageTopPad.statsInfo .popIn .closeBtn,
.home .popIn .closeBtn,
.popOuter.indv .popIn .closeBtn {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 32px;
    height: 32px;
    background-color: #ffffff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: none;
    outline: none;
    text-align: center;
}
.inrPageTopPad.statsInfo .popIn .closeBtn svg,
.home .popIn .closeBtn svg,
.popOuter.indv .popIn .closeBtn svg {
    font-size: 32px;
}
.home .announce .popIn {
    background-color: #00355B;
    color: #ffffff;
    padding-top: 1.5em;
}
.home .announce .popIn .popInCont {
    height: calc(90vh - 6em);
}
.home .announce .popIn .closeBtn {
    top: 0.8em;
    right: 0.8em;
    background-color: transparent;
    color: #ffffff;
}
.announce .annItemWrap {
    height: auto;
    border-bottom: solid 0.5px rgba(255,255,255,0.1);
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 70px;
}
.announce .annItemWrap:first-of-type {
    padding-top: 0;
}
.announce .annItemWrap:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
}
.announce .annItemWrap span.anIco {
    top: 1.8em;
}
.announce .annItemWrap:first-of-type span.anIco {
    top: 0;
}

.campus .banner {
    position: relative;  
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('../../public/campus/banner.jpg');
}
.campus .banner::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(251,251,255,0.8);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
}
.campus .banner .container-lg {
    position: relative;
    z-index: 1;
}
.campus .banner .infoTxt {
    max-width: 800px;
    margin: 0 auto;
    padding-top: 2em;
}

.whitePanelTopM {
    background-color: #ffffff;
    margin-top: -6em;
    -webkit-box-shadow: 0px 0px 43.1611px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 43.1611px rgba(0, 0, 0, 0.1);
    line-height: 0;
}
.campDtls {
    margin-bottom: 4em;
}
.campDtls h3 {
    position: relative;
    border-bottom: solid 2px #00355B;
    min-height: 38px;
    margin-bottom: 1em;
}
.campDtls h3 > span {
    position: absolute;
    left: 0;
    bottom: -6px;
    padding: 0 12px 0 0;
    background-color: #ffffff;
    color: #00355B;
}
.campDtls .order-md-1.text-md-end {
    text-align: left !important;
}
.campDtls p {
    text-align: justify;
}
section.z0 {
    position: relative;
    z-index: 0;
}
.topPerformer .whitePanelTopM {    
    position: relative;
    z-index: 1;
    margin-top: -7.2em;
    min-height: 180px;
    line-height: 1.4;
}
.tPerBox {
    height: 100%;
    background-color: #FBFBFF;
    padding: 12px;
}
.tPerBox img {
    width: 80px;
    height: 80px;
    padding: 4px;
    border: solid 1px #00355B;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.resultsGridNav a.btn {
    outline: none;
    border: none;
}
.resultGrid .bgBlue {
    color: #ffffff;
}
.resultGrid h2.gridTitle {
    position: relative;
    padding-top: 12px;
    margin-bottom: 1em;
}
.resultGrid h2.gridTitle::before {
    content: '';
    width: 40px;
    height: 3px;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
}
ul.topLblFltr {
    list-style: none;
    margin: 0 0 1.5em;
    padding: 0;
}
ul.topLblFltr ul {
    list-style: none;
    margin: 6px 0 0;
    padding: 0;
}
ul.topLblFltr li {
    padding: 0;
    margin: 0 0 10px;
    list-style: none;
}
ul.topLblFltr li button {
    padding: 8px 12px;
    color: #ffffff;
    font-weight: 600;
    background-color: transparent;
    background-image: none;
    border: none;
    width: 100%;
    display: block;
    text-align: left;
    outline: none;
    -webkit-border-radius: 6px;
    border-radius: 6px;
}
ul.topLblFltr ul li {
    margin-bottom: 2px;
}
ul.topLblFltr li.active button,
.vResultsTabs .nav-item .nav-link.active {
    background-color: rgba(255,255,255,0.2);
}
ul.topLblFltr ul li button,
.vResultsTabs .nav-item .nav-link {
    padding: 8px 12px;
    position: relative;
    font-weight: 400;
    color: #ffffff;
}
.vResultsTabs .nav-item .nav-item .nav-link {
    padding-left: 30px;
}
ul.topLblFltr ul li.active button,
.vResultsTabs .nav-item .nav-link.active {
    font-weight: 600;
}
ul.topLblFltr ul li button::before,
.vResultsTabs .nav-item .nav-item .nav-link::before {
    content: '';
    width: 4px;
    height: 4px;
    background-color: #ffffff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    left: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.resultGrid .table-responsive {
    max-height: 90vh;
    min-height: 400px;
    overflow: auto;
}
.vResultsTabs .bgBlue > .p-4 {
    min-height: 400px;
    max-height: 90vh;
    overflow: auto;
}
.resultGrid .table-responsive .table {
    margin: 0;
}
.resultGrid .table-responsive .table td {
    padding: 8px 16px;
    border: solid 1px #c2c2c2;
}
.resultGrid .table-responsive .table td:first-child {
    width: 85px;
}
.resultGrid .table-responsive::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}   
.resultGrid .table-responsive::-webkit-scrollbar-thumb {
    background-color: #ddd;
}
.vResultsTabs .nav-item {
    margin-bottom: 6px;
}
.vResultsTabs .nav-item h4 {
    font-size: 1em;
    font-weight: 400;
}
.vResultsTabs .flex-column.nav {
    margin-bottom: 0.6em;
}
.table-responsive .table.broadTable {
    min-width: 1200px;
}
.resultGrid .table-responsive .table.broadTable tr td:first-child,
.resultGrid .table-responsive .table tr td.w160 {
    width: 160px;
    text-align: left;
}
.vResultsTabs .accordion,
.vResultsTabs .accordion .accordion-item,
.vResultsTabs .accordion .accordion-button {
    background-color: transparent;
    border: none;
    color: #ffffff;
}
.vResultsTabs .accordion .accordion-button {
    padding: 10px 0 !important;
    outline: none;
    -webkit-box-shadow: 0 0 0 transparent !important;
    box-shadow: 0 0 0 transparent !important;
}
.vResultsTabs .accordion .accordion-button::after {
    -webkit-filter: invert(1) grayscale(1) brightness(1);
    filter: invert(1) grayscale(1) brightness(1);
}
.vResultsTabs .accordion .accordion-body {
    padding: 6px 0 0;
}

.fxdHThumb {
    height: 120px;
    overflow: hidden;
    text-align: center;
    background-color: #ffffff;
}
.fxdHThumb img {
    height: auto;
    width: 100%;
    display: inline-block;
}
.logTxtDnger {  
    color: #cf0000;
    font-size: 12px;
}
.inrPageTPAdmin .highcharts-credits {
    display: none;
}
.footer .btn.p-0:focus {
    border: none;
}

.resultsGridNav a.btn {
    background-color: rgba(0, 53, 91, 0.1);
    color: #00355B;
    border: solid 1px #00355B;
    min-width: 192px;
}
.resultsGridNav a.btn:hover,
.resultsGridNav a.btn:focus {
    background-color: #00355B;
    color: #ffffff;
}
.normVTabs .flex-column.nav-pills {
    border-style: solid;
    border-width: 1px 0 0;
    border-color: #c2c2c2;
}
.normVTabs .flex-column.nav-pills .nav-item {
    border-bottom: solid 1px #c2c2c2;
}
.normVTabs .flex-column.nav-pills .nav-link {
    color: #00355B;
    -webkit-border-radius: 0;
    border-radius: 0;
}
.normVTabs .flex-column.nav-pills .nav-link.active {
    background-color: #00355B;
    color: #ffffff;
}
.loadingContainer {
    text-align: center;
    padding: 3em 0;
}
.slick-slide .fxdHThumb.p-2 {
    background-color: transparent;
}
.slick-slide .fxdHThumb.p-2 img {
    width: 100%;
    min-height: 120px;
    height: auto;
    object-fit: cover;
}
.table-responsive.bdrTop {
    border-top: solid 1px #dedede;
}
.table.sList {
    position: relative;
    margin: 0;
}
.table.sList thead {
    position: sticky;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: #ffffff;
}
.table.sList td, .table.sList th {
    border: none;
}
.table.sList tbody tr {
    border-bottom: solid 6px #ffffff;
}
.table.sList tbody tr:last-child {
    border-bottom: none;
}
.table.sList tbody tr td {
    background-color: #FBFBFF;
    vertical-align: middle;
}
.table.sList tbody tr td:first-child {
    -webkit-border-radius: 6px 0 0 6px;
    border-radius: 6px 0 0 6px;
}
.table.sList tbody tr td:last-child {
    -webkit-border-radius: 0 6px 6px 0;
    border-radius: 0 6px 6px 0;
}
.table.sList tbody tr td span.avatar {
    position: relative;
    z-index: 0;    
}
.h300p {
    height: 380px;
}
.w80p {
    width: 80px;
}
.pLAName {
    padding-left: 60px !important;
}
.contLayout.bdr0 {
    border: none;
}
.filterBar {
    background-color: #00355B;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    color: #ffffff;
    -webkit-box-shadow: 0px 0px 43.1611px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 43.1611px rgba(0, 0, 0, 0.1);
}
.filterDD {
    text-align: left;
    padding: 2em 1em 1em;
}
.mh300 {
    min-height: 420px;
}
.contLayout .filterBar li {
    margin: 0 15px;
}
.contLayout .filterBar .nav-tabs {
    border: none;
}
.contLayout .filterBar .nav-tabs button.nav-link {
    padding: 16px 0;
    background-color: transparent;
    border-style: solid;
    border-width: 0 0 3px;
    border-color: transparent;
    outline: none;
    color: #ffffff;
    font-weight: 600;
}
.contLayout .filterBar .nav-tabs button.nav-link.active {
    border-color: #ffffff;
}
ul.nonList,
ul.nonList li {
    list-style: none;
    margin: 0;
    padding: 0;
}
.nonList li {
    position: relative;
}
.nonList li > input {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.nonList.year li > span {
    padding: 9px 0;
    display: block;
    opacity: 0.7;
    border-style: solid;
    border-width: 1px 0;
    border-color: transparent;
    font-size: 14px;
}
.nonList.year li > input:checked + span {
    opacity: 1;
    border-color: #ffffff;
    font-weight: 700;
}
.contLayout .filterDD > h2 {
    position: relative;    
    min-height: 20px;
    border-bottom: solid 1px #ffffff;
    margin: 0 0 32px;
}
.contLayout .filterDD > h2 > span {
    display: inline-block;
    position: absolute;
    padding: 0 12px 0 0;
    background-color: #00355B;
    left: 0;
    top: 5px;
    font-size: 18px;
    color: #ffffff;
}
.contLayout ul.nonList.checkFlds li {
    padding-left: 29px;
    margin: 0 0 4px;
}
ul.nonList.checkFlds li svg {
    opacity: 0.7;
    font-size: 100%;
    position: absolute;
    top: 5px;
    left: 0;
}
ul.nonList.checkFlds li svg.cChecked {
    display: none;
}
ul.nonList.checkFlds li > div > span {
    display: inline-block;
    opacity: 0.7;
    font-size: 14px;
}
ul.nonList.checkFlds li > input:checked + div > svg.cBlank {
    display: none;
}
ul.nonList.checkFlds li > input:checked + div > svg.cChecked {
    opacity: 1;
    display: inline-block;
}
ul.nonList.checkFlds li > input:checked + div span {
    opacity: 1;
    font-weight: 700;
}
.norWhiteBG {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 43.1611px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 43.1611px rgba(0, 0, 0, 0.1);
}
.graphHldr .highcharts-container,
.graphHldr .highcharts-root {
    max-width: 100% !important;
    max-height: 100% !important;
    display: block;
}
.graphHldr .highcharts-credits {
    display: none;
}
.filterBar {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    top: 115px;
    z-index: 9;    
}
.filterBar .position-relative .btn.btn-secondary {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #00355B;
}
.filterBar .position-relative .applyFltrBtn {
    position: relative;
    top: 10px;
}
.btn.vAll.p-0 {
    color: #ffffff;
    padding-bottom: 2px;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-bottom: solid 0.5px #ffffff;
    font-size: 90%;
}
.btn.vAll.p-0:hover {
    border-bottom-color: transparent;
}
.ratio iframe {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    left: 0;
    top: 0;
    border: 0;
}



@media (min-width: 992px) {    
    .whiteBG::before {
        width: 80%;
        height: 85%;
    }
    h1.leftBdr {
        position: relative;             
    }
    h1.fsB {
        font-size: 3em;  
        line-height: 1; 
    }
    h1.leftBdr::before {
        content: '';
        position: absolute;
        left: -20px;
        top: 0;
        width: 3px;
        height: 100%;
        background-color: #00355B;
    }
    ul.programs li {
        width: 50%;
    }
    ul.programs li .listBox {
        padding: 3em;
    }
    ul.programs li:nth-child(2) {
        padding-top: 1.6em;
    }
    ul.programs li:nth-child(4) {
        padding-top: 0;
    }
    .navyBanner {
        font-size: 32px;
    }
    .navyBanner h1 {
        font-size: 46px;
    } 
    .topCrop img {
        margin-top: -120px;
    }   
    .twoFold::before {
        content: '';
        width: 50%;
        height: 100%;
        background-color: #00355B;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
    }
    .twoFold .container-lg {
        position: relative;
        z-index: 1;
    }
    .table-responsive.outerBdr .table th,
    .table-responsive.outerBdr .table td {
        padding-left: 1em;
        padding-right: 1em;
        color: #00355B;
    }
    .sideBoxLog {
        height: 100vh;        
    }
    .sideBoxLog .copyStamp {
        text-align: right;
    }
    .contForm .mb-4 > label {
        font-size: 18px;
    }
    .hTabs.donations .nav-tabs .nav-item {
        padding: 0 1em;
    }    
    .hTabs.donations .nav-tabs .nav-item:first-of-type::after {
        right: -1em;
    }
    .table.spacing td {
        padding: 6px 12px;
    }
    .table.spacing td:first-child {
        padding-left: 0;
    }
    .row.normVTabs .pe-md-4 {
        margin-right: 1.5em;
        border-right: solid 1px #c2c2c2;
    }
    .filterBar .position-relative .applyFltrBtn {
        position: absolute;
        top: 10px;
        right: 12px;
    }

    .popOuter.indv .popIn .popInCont {        
        height: calc(90vh - 2em);
        overflow: auto;
    }
}

@media (max-width: 991px) {
    .table.minWidth {
        min-width: 1300px;
    }
    .table.minWidthSml {
        min-width: 800px;
    }
    .selection > .col-lg:nth-child(3)::before,
    .selection > .col-lg:nth-child(5)::before {
        width: calc(100% - 1em);
        right: 1em;
    }
    .selection > .col-lg:nth-child(4)::before {
        width: 1em;
    }
    .selBox {
        font-size: 12px;
    }    
    .selBox .p-3,
    .selBox .px-3 {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }
    .twoFold .row > .col-12:first-of-type {
        background-color: #00355B;
    }
    .graphHldr .highcharts-container, 
    .graphHldr .highcharts-root {
        max-height: 240px !important;
    }   
    .results .table-responsive .table.sList {
        min-width: 1050px;
        font-size: 12px;
    } 
    .results .table-responsive .table.sList th,
    .results .table-responsive .table.sList td {
        max-width: 220px;
    }
    .results .norWhiteBG h6 {
        font-size: 0.75rem;
    }
}

@media (min-width: 1200px) and (max-width: 1299px) {
    .topPerformer .whitePanelTopM {  
        margin-top: -7em;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .topPerformer .whitePanelTopM {  
        margin-top: -6em;
    }
}
@media (min-width: 821px) and (max-width: 991px) {
    .topPerformer .whitePanelTopM {  
        margin-top: -5.5em;
    }
}
@media (min-width: 768px) and (max-width: 820px) {
    .topPerformer .whitePanelTopM {  
        margin-top: -4.8em;
    }
}
@media (min-width: 701px) and (max-width: 767px) {
    .topPerformer .whitePanelTopM {  
        margin-top: -4.2em;
    }
}
@media (min-width: 620px) and (max-width: 700px) {
    .topPerformer .whitePanelTopM {  
        margin-top: -4em;
    }
}
@media (min-width: 521px) and (max-width: 619px) {
    .topPerformer .whitePanelTopM {  
        margin-top: -3.5em;
    }
}
@media (min-width: 400px) and (max-width: 520px) {
    .topPerformer .whitePanelTopM {  
        margin-top: -2.2em;
    }
}
@media (min-width: 360px) and (max-width: 399px) {
    .topPerformer .whitePanelTopM {  
        margin-top: -2.1em;
    }
}
@media (min-width: 320px) and (max-width: 359px) {
    .topPerformer .whitePanelTopM {  
        margin-top: -2em;
    }
}

@media (max-width: 767px) {
    .slick-slide .fxdHThumb.p-2 {
        padding: 0 !important;
        height: 50px;
    }
    .slick-slide .fxdHThumb.p-2 img {
        height: 50px;
        min-height: 50px;
    }
    .contLayout .filterBar li {
        margin: 0 8px;
        font-size: 12px;
    }
}



/* === 09-2023 ==== */

.bannerWrap .btn-outline-secondary { color: #ffffff; }
.coachCar.slick-slider .slick-slide { padding: 0; }
.coachCar.slick-slider .slick-slide .bsBox { min-height: 357px; }
.dnldSamples { 
    padding: 18px 0 0;
    margin-top: 2rem;
    text-align: center;
    border-top: solid 1px rgba(255,255,255,0.3);
    font-size: 16px;
}
.dnldSamples .btn.btn-lg {
    font-size: 16px;
}
.dnldSamples .btn.btn-lg svg {
    margin-right: 10px;
}

.slick-slider .slick-slide .fxdHThumb {
    opacity: 0.4;
}
.slick-slider .slick-slide.slick-current .fxdHThumb {
    opacity: 1;
}

@media all and (min-width: 1200px) {
    img.img-fluid.mxW800 {
        max-width: 800px;
    }
}

.hTabs.media .nav-tabs {
    overflow-y: hidden;
    overflow-x: scroll;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    justify-content: flex-start !important;

}
.hTabs.media .nav-tabs .nav-item {
    margin: 0 6px;
}

.hTabs.media .nav-tabs::-webkit-scrollbar {
    height: 4px;
}   
.hTabs.media .nav-tabs::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}   
.hTabs.media .nav-tabs::-webkit-scrollbar-thumb {
    background-color: #999999;
}
.hTabs.media .nav-tabs .nav-item .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

/* For other styles */
