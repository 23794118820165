* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .home{
    padding-top: 112px;
}
.home-bg-img{
    background-color: #424242;
    background-image: url('/public/Home.png');
    background-repeat: no-repeat;
    position: relative;
    height: 780px;
    background-size: cover;
    background-position: center;
}

.spotH8Img {
    background-color: #424242;
    background-image: url('/public/results/JEE-Main-2024-Results.jpg');
    background-repeat: no-repeat;
    position: relative;
    height: 780px;
    background-size: contain;
    background-position: center;
}

.side-content{
    background-color:  #00355B;
    font-size: 14px;
    color: #ffffff;
}

.annoumcement-section{
    font-family: 'Work Sans';
font-style: normal;
font-weight: 500;
font-size: 21px;
line-height: 25px;
color: #FFFFFF;
padding-top: 55px;
padding-bottom: 12px;
text-align: center;
border-bottom: 0.96038px solid #FFFFFF;
}

.icon-notification{
    background-image: url('/public/notification-icon.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    height: 100px;

}


.text {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 50.8034px;
    color: #ffffff;
    padding: 150px 0px 0px 100px;
  }
  .inlinetext { 
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 21.1681px;
    /* identical to box height */
    color: #ffffff;
  }
  .student-Qualified-Section {
    max-width: 827px;
    height: 157px;
    background: rgba(0, 53, 91, 0.11);
    -webkit-backdrop-filter: blur(44.204px);
    backdrop-filter: blur(44.204px);
    border-radius: 10px;
    margin-left: 14%;
    margin-top: 130px;
    padding: 20px;
    color: #ffffff;
  }
  .student-qualified-text{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
  
  }
  .qualified-section {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 21.1681px;
    line-height: 25px;
    color: #ffffff;
    border-left: 2px solid white;
    padding-left: 1em;
    margin: 0; 
    height: 50px; 
  }
  .qualified-section p {
    margin: 6px 0 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1.3;
  }
  .box{
      width: 200px;
      height: 877px;
      margin-top: 100px;
  background: #00355B;
  }
  

  .icon-notification{
    height: 100px;
    color: #FFFFFF;
    display: flex;
  }

  .outline-icon{
    height: 62px;
    width: 62px;
    background-color: #FFFFFF;
    position: relative;
    top: 20px;
    left:10px

  }
  .icon-text{
   padding: 30px 30px 10px 30px;
   font-weight: 700;
   font-size: 16px;
  }
  .View-more{
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
padding: 10px 20px 10px 60px;
  }
  .register-button{
    text-decoration-line: underline;
    background-color: #FBC733;
    position: relative;
    left: 80px;
  }
.searchbar{
    margin-left: 100px;
    display: flex
   
}
.searchbar-input{
  border-radius: 85.3571px 0px 0px 85.3571px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 5px 20px 5px 46px;
  width: 100%;
  border: none;
  outline: none;
  height: 46px;
  -webkit-backdrop-filter: blur(8.53571px);
  backdrop-filter: blur(8.53571px);
  color: #ffffff;
}
.searchbar-input::placeholder {
  color: #ffffff;
}
.search-button{
  padding: 0 40px;
  border: none;
  height: 46px;
  outline: none;

background: #CA2650;
box-shadow: 0px 17.0714px 42.6786px rgba(0, 0, 0, 0.5);
-webkit-border-radius: 0 85.3571px 85.3571px 0;
border-radius: 0 85.3571px 85.3571px 0;
font-family: inherit;
font-style: normal;
font-weight: 700;
font-size: 15.3643px;
color: #FFFFFF;
width: 100%;
}

  /* ======== Modifications ============ */
  .bannerContSec {
    padding: 10% 14%;
  }
  .bannerContSec .text {
    padding: 0 0 0 22px;
    border-left: solid 6px #ffffff;
    margin-bottom: 30px;
    line-height: 1;
  }
  .bannerContSec .text .inlinetext {
    line-height: 1.5;
  }
  .bannerContSec .searchbar {
    margin-left: 0;
    max-width: 720px;
  }
  .srchBtnW {
    width: 120px;
  }
  .searchbar .pe-2::after {
    content: '';
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../images/svg/akar-icons_search.svg');
    position: absolute;
    left: 18px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .ylowBtn {
    display: inline-block;
    padding: 0 0 2px;
    background-color: transparent;
    background-image: none;
    border-style: solid;
    border-width: 0 0 1px;
    border-color: #FBC733;
    color: #FBC733;
    font-size: 12px;
    outline: none;
    margin: 0;
    text-decoration: none;
  }
  .ylowBtn:hover {
    color: #FBC733;
    border-color: transparent;
  }

  .side-content .nav-item {
    margin-bottom: 2.5em;
  }

  .side-content .slick-slider {
    margin-left: -10px;
  }
  .side-content .slick-slider .slick-slide {
    padding: 0 0 0 10px;
  }
  h5.annTitle {
    font-family: "Work Sans";
    font-size: 20px;
    font-weight: 500;
    border-bottom: solid 0.5px #ffffff;
    padding-bottom: 8px;
  }

  .annItemWrap {
    position: relative;
    padding-left: 62px;       
  }
  .annItemWrap span.anIco {
    width: 48px;
    height: 48px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;    
    position: absolute;
    left: 0;
    top: 1px;
  }
  .annItemWrap span.anIco.mic {
    background-image: url('../../public/svg/announce-ico.svg');
  }
  .annItemWrap span.anIco.speaker {
      background-image: url('../../public/svg/announce-ico-speaker.svg');
  }
  .annItemWrap span.anIco.scholar {
      background-image: url('../../public/svg/announce-ico-scholar.svg');
  }
  .annItemWrap span.anIco.brainstorm {
      background-image: url('../../public/svg/announce-ico-brainstorm.svg');
  }
  .annItemWrap span.anIco.intern {
      background-image: url('../../public/svg/announce-ico-interns.svg');
  }
  .annItemWrap span.anIco.program {
      background-image: url('../../public/svg/announce-ico-program.svg');
  }

  .side-content .slick-slider.slick-vertical .slick-track {
    height: auto !important;
  }
  .side-content .slick-slider.slick-vertical .slick-slide.slick-current .p-4,
  .side-content .slick-slider.slick-vertical .slick-slide:hover .p-4 {
    background-color: #014371;
    position: relative;
  }
  .side-content .slick-slider.slick-vertical .slick-slide.slick-current .p-4::before,
  .side-content .slick-slider.slick-vertical .slick-slide:hover .p-4::before {
    content: '';
    width: 10px;
    height: 100%;
    background-color: #00355B;
    border-radius: 10px;
    position: absolute;
    left: -10px;
    top: 0;
  }  
  .annItemWrap {
    height: 220px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 12px;
  }
  .annItemWrap p {
    font-size: 85%;
    margin: 0;
  }

  @media (max-width:991px) {
    .col-12.home-bg-img {
      height: auto;
    }
    .bannerContSec {
      padding: 40px 16px;
    } 
    .bannerContSec .text {
      font-size: 32px;
    }   
    .student-Qualified-Section {
      margin: 30px 16px;
      height: auto;
    }
  }

  @media (min-width: 992px) and (max-width: 1400px) {
    .home-bg-img .bannerContSec {
      padding: 10% 6% 8%;
    }
    .home-bg-img .student-Qualified-Section {
      margin-left: 2%;
    }
  }

  @media (max-width: 620px) {
    .home-bg-img {
      height: 500px;
    }    
    .spotH8Img {
      height: 380px;
    }
    .student-Qualified-Section .qualified-section {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .student-Qualified-Section .qualified-section p {
      font-size: 10px;
    }
  }

  @media (max-width: 1199px) {
    .annItemWrap {
      height: 130px;
    }
  }
  @media (min-width: 1200px) {
    .col-lg-9.home-bg-img {
      width: 70%;
    }
    .col-lg-3.side-content {
      width: 30%;
    }  
    .bannerContSec .text {
      margin-top: 50px;
    }
  }
