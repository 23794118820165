.here-it-section{
    background: #FBFBFF;
    border-top: solid 3px #E9E9FD;
    padding: 3em 0 6em;
}

.testimonyWrap {
    max-width: 100%;
    margin: 0 auto;
}
.whiteBox {
    background-color: #ffffff;
    padding: 1em;
}
.testimonyWrap .whiteBox {
    padding: 3.4em 2em 2em;
    position: relative;
    height: 100%;
    -webkit-box-shadow: 0 0 4px rgb(0,0,0,0.4);
    box-shadow: 0 0 4px rgb(0,0,0,0.4);
    -webkit-border-radius: 6px;
    border-radius: 6px;
}
.testimonyWrap .whiteBox::before,
.testimonyWrap .whiteBox::after {
    content: '';
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url('../../public/svg/quote-start.svg');
    position: absolute;
}
.testimonyWrap .whiteBox::before {    
    top: 9px;
    left: 1em;
}
.testimonyWrap .whiteBox::after {    
    bottom: 2em;
    right: 1em;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}
.testimonyWrap .slick-slider .slick-prev, 
.testimonyWrap .slick-slider .slick-next {
    display: block;
    width: 42px;
    height: 42px;
    background-image: none !important;
    top: auto;
    bottom: -54px;
    -webkit-transform: translate(0);
    transform: translate(0);
    background-color: #00355B;
}
.testimonyWrap .slick-slider .slick-prev {
    left: 5px;
}
.testimonyWrap .slick-slider .slick-next {
    right: 5px;    
}
.testimonyWrap .slick-slider .slick-prev::after, 
.testimonyWrap .slick-slider .slick-next::after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-size: 33% auto !important;
    background-image: url('../../public/svg/testimonial-arrow-left.svg') !important;
    background-position: center center !important;
    -webkit-filter: brightness(100) grayscale(0);
    filter: brightness(100) grayscale(0);
}
.testimonyWrap .slick-slider .slick-next::after {
    -webkit-transform: translate(-50%, -50%) rotate(-180deg);
    transform: translate(-50%, -50%) rotate(-180deg);
}
.testimonyWrap cite {
    display: block;
    position: absolute;
    width: 96%;
    z-index: 9;
    left: 50%;
    bottom: 1.5em;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    color: #424242;
    border-top: solid 0.5px #f0f0f0;
    padding-top: 1em;
}
.testimonyWrap cite h6,
.testimonyWrap cite p {
    margin: 0;
}

.mediaBox {
    height: 320px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background-color: #ffffff;
    position: relative;
    overflow: hidden;
}
.mediaBox::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.8);
    background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(255,255,255,0) 55%);
}
.mediaBox.noImg {
    background-size: 80% auto;
    background-position: center top 16px;
}
.mediaBox .d-flex {
    position: relative;
    z-index: 2;
    color: #ffffff;    
}
.mediaBox .d-flex h6 {
    font-weight: 600;
}
.mediaBox .btn.btnPlay {
    width: 48px;
    height: 48px;
    display: block;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../public/svg/bluePlayIco.svg');
    cursor: pointer;
    border: none;
    outline: none;
}
.mediaBox .btn.btnPlay:hover {
    opacity: 0.85;
}

.testimonyWrap .whiteBox .scrollCont {
    max-height: 180px;
    padding-right: 1.5em;
    overflow-x: hidden;
    overflow-y: auto;
}
.testimonyWrap .whiteBox .scrollCont p:last-of-type {
    margin-bottom: 0;
}
.testimonyWrap .whiteBox ol,
.testimonyWrap .whiteBox ul {
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 0;
}
.testimonyWrap .whiteBox li {
    margin-bottom: 12px;
}
.testimonyWrap .whiteBox li:last-child {
    margin-bottom: 0;
}

@media all and (max-width: 767px) {
    .testimonyWrap .whiteBox {
        padding-bottom: 3em;
    }
    .testimonyWrap .whiteBox::after {
        bottom: 1em;
        right: 1em;
    }
    .testimonyWrap .whiteBox .scrollCont {
        max-height: 280px;
    }
}